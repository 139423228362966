import {Stack} from '@mui/material';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {FormTextField} from 'components/form/FormTextField';

export const UsersSearchPanel = () => {
    const form = useForm();
    return (
        <Form form={form}>
            <Stack>
                <FormTextField
                    name={'search'}
                    size={'small'}
                    isSearch
                    hasSearchIcon
                    placeholder={'Поиск'}
                    sx={{maxWidth: {xs: '100%', md: 300}}}
                />
            </Stack>
        </Form>
    );
};
