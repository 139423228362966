import {ThemeOptions} from '@mui/material';

export const whiteTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#7367f0',
            100: '#E3E1FC',
            200: '#C7C2F9',
            300: '#ABA4F6',
            400: '#8F85F3',
            500: '#7367F0',
            600: '#675DD8',
            700: '#6258CC',
            800: '#5C52C0',
            900: '#564DB4'
        },
        action: {
            selected: '#EEEEEF',
            disabled: '#C1BFC5'
        },
        background: {
            body: '#f8f7fa'
        },
        text: {
            primary: '#444050',
            secondary: '#6D6B77',
            disabled: '#ACAAB1'
        }
    }
};
