import {Stack} from '@mui/material';
import React from 'react';
import {UsersTabPanel} from './components/UsersTabPanel';
import UsersTable from './components/UsersTable';
import {UsersSearchPanel} from './components/UsersSearchPanel';
import {Paper} from 'components/accessory/Paper';

export const Users = () => {
    return (
        <Paper sx={{padding: 2, height: '100%'}}>
            <Stack gap={4}>
                <UsersTabPanel />
                <UsersSearchPanel />
                <UsersTable />
            </Stack>
        </Paper>
    );
};
