import {FieldValues, FormProvider, UseFormReturn} from 'react-hook-form';
import {ReactNode} from 'react';

export type FormProps<T extends FieldValues> = {
    form: UseFormReturn<T>;
    children?: ReactNode;
    onSubmit?: (data: T) => void;
    style?: object;
};

export const Form = <T extends FieldValues>(props: FormProps<T> & { fullWidth?: boolean }) => {
    const {form, children, onSubmit, fullWidth, ...rest} = props;

    const {handleSubmit} = form;
    const fullWidthStyle = {flexGrow: 1, display: 'flex', flexDirection: 'column'};
    const style = {...props.style, ...(fullWidth ? fullWidthStyle : {})};
    return (
        <FormProvider {...form}>
            <form
                style={style}
                noValidate
                onSubmit={(e) => {
                    onSubmit && handleSubmit(onSubmit)();
                    e.preventDefault();
                }}
                {...rest}>
                {children}
            </form>
        </FormProvider>
    );
};
