import cookies from 'js-cookie';

export const getTokens = () => ({
    accessToken: cookies.get('accessToken'),
    refreshToken: cookies.get('refreshToken')
});
export const setTokens = (response: any) => {
    cookies.set('accessToken', response?.access_token);
    cookies.set('refreshToken', response?.refresh_token);
};
export const removeTokens = () => {
    cookies.remove('accessToken');
    cookies.remove('refreshToken');
};
