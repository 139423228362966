import React, {useEffect} from 'react';
import {Box, Stack, Tab, Tabs} from '@mui/material';

import {useQsParams} from 'utils/hooks/useQsParams';

export const UsersTabPanel = () => {
    const [params, setParams] = useQsParams();
    const {tab} = params;

    useEffect(() => {
        if (!tab) setParams({tab: 'all'});
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setParams({tab: newValue});
    };
    
    return (
        <Stack>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    aria-label='tabs users'
                    scrollButtons={'auto'}
                    variant={'scrollable'}>
                    {TABS_LIST.map((tab) => (
                        <Tab label={tab.title} key={tab.value} value={tab.value} />
                    ))}
                </Tabs>
            </Box>
        </Stack>
    );
};

const TABS_LIST = [
    {title: 'Все сотрудники', value: 'all'},
    {title: 'Новые сотрудники', value: 'new'},
    {title: 'Должности', value: 'positions'},
    {title: 'Подразделения', value: 'divisions'}
];
