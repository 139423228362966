import {Paper} from 'components/accessory/Paper';
import {Typography} from '@mui/material';
import {paperPaddingMixin} from 'utils/mixins';

export const MyDocuments = () => {
    return (
        <Paper sx={{...paperPaddingMixin, height: 600}}>
            <Typography variant={'h5'}>Мои документы</Typography>
        </Paper>
    );
};
