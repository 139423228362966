import {Paper} from 'components/accessory/Paper';
import {Typography} from '@mui/material';
import {paperPaddingMixin} from 'utils/mixins';

export const Colleagues = () => {
    return (
        <Paper sx={{...paperPaddingMixin, width: '100%', height: 400}}>
            <Typography variant={'h5'}>Мои коллеги</Typography>
        </Paper>
    );
};
