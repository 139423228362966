import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FeedIcon from '@mui/icons-material/Feed';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SchoolIcon from '@mui/icons-material/School';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export const drawerWidth = 260;

export enum ROUTES {
    BASE = '/',
    AUTH = '/auth',
    PROFILE = '/profile',
    NEWS = '/news',
    REQUESTS = '/requests',
    KNOWLEDGE_BASE = '/knowledge-base',
    STORAGE = '/storage',
    AUDITS = '/audits',
    USERS = '/users',
    TEST = '/test',
    TESTEST = '/test/test'
}

export enum ERROR_MESSAGE {
    REQUIRED = 'Обязательное поле',
    REQUIRED_EMPTY = ' ',
    EMAIL = 'Неверный формат e-mail',
    INVALID_CREDENTIALS = 'Неверная почта или пароль',
    USER_BLOCKED = 'Пользователь заблокирован. Обратитесь к администратору системы'
}

export const MENU_ITEMS = [
    {
        title: 'Меню',
        links: [
            {
                title: 'Профиль',
                href: ROUTES.PROFILE,
                icon: <AccountCircleIcon />
            },
            {
                title: 'Новости',
                href: ROUTES.NEWS,
                icon: <FeedIcon />
            },
            {
                title: 'Заявки',
                href: ROUTES.REQUESTS,
                icon: <ChecklistIcon />
            },
            {
                title: 'База знаний',
                href: ROUTES.KNOWLEDGE_BASE,
                icon: <SchoolIcon />
            },
            {
                title: 'Хранилище',
                href: ROUTES.STORAGE,
                icon: <FolderOpenIcon />
            },
            {
                title: 'Аудиты',
                href: ROUTES.AUDITS,
                icon: <HeadphonesIcon />
            },
            {
                title: 'test2',
                children: [
                    {
                        title: 'test9',
                        href: ROUTES.TESTEST
                    }
                ]
            }
        ]
    },
    {
        title: 'Системные настройки',
        links: [
            {
                title: 'Пользователи',
                href: ROUTES.USERS,
                icon: <PeopleAltIcon />
            }
        ]
    }
];
