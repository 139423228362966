import {alpha, IconButton, Stack, Toolbar, useMediaQuery} from '@mui/material';
import * as React from 'react';
import {styled, Theme} from '@mui/material/styles';
import {drawerWidth} from 'utils/constants';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {useAuthContext} from 'context/authContext';
import MenuIcon from '@mui/icons-material/Menu';
import {transitionMixin} from 'utils/mixins';
import {Notifications} from 'components/layouts/MainLayout/components/AppBar/components/Notifications';
import {SwitchTheme} from 'components/layouts/MainLayout/components/AppBar/components/SwitchTheme';
import {UserMenu} from 'components/layouts/MainLayout/components/AppBar/components/UserMenu';

export const AppBarTop = ({
    open,
    setOpen,
    blockOpen
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    blockOpen: boolean;
}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const {me} = useAuthContext();

    return (
        <AppBar
            position='sticky'
            open={open}
            blockOpen={blockOpen}
            sx={(theme) => ({
                boxShadow: isMd ? 0 : 1,
                borderRadius: '6px',
                top: {xs: 0, md: 16},
                ...transitionMixin(theme),
                marginLeft: {
                    xs: 1,
                    md: blockOpen ? `calc(${drawerWidth + 16}px + 1px)` : `calc(${theme.spacing(10)} + 1px)`
                }
            })}>
            <Toolbar
                sx={(theme) => ({
                    ...theme.mixins.toolbar
                })}>
                {!isMd && (
                    <IconButton onClick={() => setOpen(true)}>
                        <MenuIcon />
                    </IconButton>
                )}

                {me && (
                    <Stack direction={'row'} sx={{ml: 'auto'}} gap={1} alignItems={'center'}>
                        <SwitchTheme isOpenLeftMenu={open} />
                        <Notifications />
                        <UserMenu isOpenLeftMenu={open} />
                    </Stack>
                )}
            </Toolbar>
        </AppBar>
    );
};
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps & {blockOpen: boolean}>(({theme, open, blockOpen}) => ({
    zIndex: theme.zIndex.drawer - 1,
    marginTop: 8,
    marginRight: 8,
    width: 'auto',
    [theme.breakpoints.up('md')]: {
        marginTop: 16,
        width: blockOpen
            ? `calc(100% - ${drawerWidth + 16}px - 2px - 16px + 1px)`
            : `calc(100% - ${theme.spacing(8)} - 2px - 32px)`
    },
    // backgroundColor: 'rgba(255, 255, 255, 0.95)',
    backgroundColor: alpha(theme.palette.background.paper, 0.88),
    borderRadius: '6px',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}
