import {CSSObject, Theme} from '@mui/material/styles';

export const transitionMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
});

export const paperPaddingMixin = {p: 3};
