import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import {ROUTES} from 'utils/constants/index';

export const MENU_ITEMS = [
    {
        title: 'Меню',
        links: [
            {
                title: 'Профиль',
                href: ROUTES.PROFILE,
                icon: <AccountCircleOutlinedIcon />
            },
            {
                title: 'График работы',
                href: ROUTES.SCHEDULER,
                icon: <EditCalendarOutlinedIcon />
            },
            {
                title: 'Новости',
                href: ROUTES.NEWS,
                icon: <FeedOutlinedIcon />
            },
            {
                title: 'Заявки',
                href: ROUTES.REQUESTS,
                icon: <ChecklistIcon />
            },
            {
                title: 'База знаний',
                href: ROUTES.KNOWLEDGE_BASE,
                icon: <SchoolOutlinedIcon />
            },
            {
                title: 'Хранилище',
                href: ROUTES.STORAGE,
                icon: <FolderOpenOutlinedIcon />
            },
            {
                title: 'Аудиты',
                href: ROUTES.AUDITS,
                icon: <HeadphonesOutlinedIcon />
            },
            {
                title: 'test2',
                children: [
                    {
                        title: 'test9',
                        href: ROUTES.TESTEST
                    }
                ]
            }
        ]
    },
    {
        title: 'Системные настройки',
        links: [
            {
                title: 'Пользователи',
                href: ROUTES.USERS,
                icon: <PeopleAltOutlinedIcon />
            }
        ]
    }
];
