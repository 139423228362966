import {Avatar, ButtonBase, IconButton, Link, Menu, MenuItem, Stack, Toolbar, useMediaQuery} from '@mui/material';
import * as React from 'react';
import {styled, Theme} from '@mui/material/styles';
import {drawerWidth, ROUTES} from 'utils/constants';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {stringAvatar} from 'utils/functions';
import {useAuthContext} from 'context/authContext';
import LogoutIcon from '@mui/icons-material/Logout';
import {removeTokens} from 'utils/functions/auth';
import {useNavigate} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import {LogoIcon} from 'assets/icons';
import MenuIcon from '@mui/icons-material/Menu';
import {transitionMixin} from 'utils/mixins';

export const AppBarTop = ({
    open,
    setOpen,
    blockOpen
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    blockOpen: boolean;
}) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const {me, isLoading} = useAuthContext();
    const fullName = `${me?.first_name} ${me?.last_name}`;
    const exitClick = () => {
        removeTokens();
        queryClient.clear();
        navigate(ROUTES.AUTH);
        handleCloseMenu();
    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpenMenu = Boolean(anchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar
            position='sticky'
            open={open}
            blockOpen={blockOpen}
            sx={(theme) => ({
                boxShadow: 2,
                borderRadius: '6px',
                top: {xs: 0, md: 16},
                ...transitionMixin(theme),
                marginLeft: {
                    xs: 1,
                    // xs: `calc(${theme.spacing(9)} + 1px)`,
                    md: blockOpen ? `calc(${drawerWidth + 16}px + 1px)` : `calc(${theme.spacing(10)} + 1px)`
                }
            })}>
            <Toolbar sx={(theme) => ({...theme.mixins.toolbar})}>
                {/*<>*/}
                {/*    <Stack component={Link} href={location.origin}>*/}
                {/*        <LogoIcon />*/}
                {/*    </Stack>*/}
                {/*</>*/}
                {!isMd && (
                    <IconButton onClick={() => setOpen(true)}>
                        <MenuIcon />
                    </IconButton>
                )}

                {me && (
                    <Stack direction={'row'} sx={{ml: 'auto'}} gap={2} alignItems={'center'}>
                        <ButtonBase
                            sx={{borderRadius: '100%'}}
                            id='basic-button'
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup='true'
                            onClick={handleOpenMenu}
                            aria-expanded={open ? 'true' : undefined}>
                            <Avatar
                                {...stringAvatar(fullName)}
                                sx={{
                                    width: 40,
                                    height: 40,
                                    fontSize: '16px'
                                }}
                            />
                        </ButtonBase>

                        <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={isOpenMenu}
                            sx={{mt: 1}}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button'
                            }}>
                            <MenuItem onClick={exitClick} sx={{gap: 1}}>
                                <LogoutIcon fontSize={'small'} /> Выход
                            </MenuItem>
                        </Menu>
                    </Stack>
                )}
            </Toolbar>
        </AppBar>
    );
};
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps & {blockOpen: boolean}>(({theme, open, blockOpen}) => ({
    zIndex: theme.zIndex.drawer - 1,
    marginTop: 8,
    marginRight: 8,
    width: 'auto',
    [theme.breakpoints.up('md')]: {
        marginTop: 16,
        width: blockOpen
            ? `calc(100% - ${drawerWidth + 16}px - 2px - 16px)`
            : `calc(100% - ${theme.spacing(8)} - 2px - 32px)`
    },
    backgroundColor: 'rgba(255, 255, 255, 0.95)',

    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}
