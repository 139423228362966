import {typography} from './typography';
import {ThemeOptions} from '@mui/material';

declare module '@mui/material/styles' {
    interface PaletteColor {
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        600?: string;
        700?: string;
        800?: string;
        900?: string;
    }

    interface TypeBackground {
        body?: string;
    }
}

export const baseTheme: ThemeOptions = {
    typography,
    shape: {
        borderRadius: 5
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1275,
            xl: 1736
        }
    },
    mixins: {
        toolbar: {
            minHeight: 56
        }
    },
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: 'xl'
            },
            styleOverrides: {
                root: ({theme}) => ({
                    [theme.breakpoints.down('md')]: {
                        padding: '0 20px'
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: '0 60px'
                    }
                })
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: 0,
                    position: 'absolute',
                    top: 'calc(100% - 1px)',
                    left: 0
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: ({theme}) => ({
                    '&.Mui-selected': {
                        '&:hover': {backgroundColor: theme.palette.primary[500]},
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.primary.contrastText
                        },
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText
                    }
                })
            }
        },
        MuiInputBase: {
            styleOverrides: {
                multiline: {alignItems: 'flex-start'},
                root: ({theme}) => ({
                    '&:before,:after': {
                        borderBottom: 'none !important'
                    }
                }),
                sizeSmall: {},
                input: {}
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {paddingRight: '8px'},
                input: ({theme}) => ({
                    '&::placeholder': {
                        color: theme.palette.text.disabled
                    }
                }),
                notchedOutline: ({theme}) => ({
                    transition: theme.transitions.create('border-color', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.standard
                    })
                })
            }
        }
    }
};
