import * as React from 'react';
import {styled, useTheme, Theme, CSSObject} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {Box, IconButton, Link, Paper, Stack, useMediaQuery} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {Outlet, useLocation} from 'react-router-dom';
import {drawerWidth, ROUTES} from 'utils/constants';
import {LeftPanelList} from 'components/layouts/MainLayout/components/LeftPanelList';
import {AppBarTop} from 'components/layouts/MainLayout/components/AppBar';
import {Logo85Icon} from 'assets/icons';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import {transitionMixin} from 'utils/mixins';
import {useEffect} from 'react';
import {ModalEffector} from 'context/modalEffector';

export default function MiniDrawer() {
    const {pathname} = useLocation();
    const leftMenuBlockStorage = Boolean(Number(localStorage.getItem('leftMenuBlock')));
    const [open, setOpen] = React.useState(leftMenuBlockStorage || false);
    const [blockOpen, setBlockOpen] = React.useState(leftMenuBlockStorage || false);
    const isAuth = pathname?.includes(ROUTES.AUTH);

    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const toggleBlockOpen = (value: boolean) => {
        setBlockOpen(value);
        window.localStorage.setItem('leftMenuBlock', String(Number(value)));
    };
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpen(open);
    };
    useEffect(() => {
        if (!isMd) {
            toggleBlockOpen(false);
            setOpen(false);
        }
    }, [isMd]);
    return (
        <>
            {!isAuth ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        backgroundColor: (theme) => theme.palette.background.body
                    }}>
                    <CssBaseline />
                    <AppBarTop {...{open, setOpen, blockOpen}} />
                    {isMd ? (
                        <Drawer
                            variant={isMd || open ? 'permanent' : 'temporary'}
                            open={open}
                            onMouseEnter={() => setOpen(true)}
                            onMouseLeave={() => (blockOpen ? {} : setOpen(false))}
                            sx={{
                                boxShadow: 4,
                                position: 'absolute'
                            }}>
                            <DrawerLeftMenu {...{open, blockOpen, toggleBlockOpen}} />
                        </Drawer>
                    ) : (
                        <MuiDrawer anchor={'left'} onClose={toggleDrawer(false)} open={open}>
                            <Stack onClick={toggleDrawer(false)}>
                                <DrawerLeftMenu {...{open, blockOpen, toggleBlockOpen}} />
                            </Stack>
                        </MuiDrawer>
                    )}

                    <Box
                        component='main'
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.body,
                            width: '100%',
                            height: '100%'
                        }}>
                        <Stack
                            flexGrow={1}
                            sx={{
                                height: '100%',
                                position: 'relative'
                            }}>
                            <Stack
                                sx={(theme) => ({
                                    flexGrow: 1,
                                    m: {xs: 1, md: 2},
                                    ...transitionMixin(theme),
                                    marginLeft: {
                                        md: blockOpen
                                            ? `calc(${drawerWidth + 16}px + 1px)`
                                            : `calc(${theme.spacing(10)} + 1px)`
                                    }
                                })}>
                                <Outlet />
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            ) : (
                <Outlet />
            )}
            <ModalEffector />
        </>
    );
}
const DrawerLeftMenu = ({
    open,
    blockOpen,
    toggleBlockOpen
}: {
    open: boolean;
    blockOpen: boolean;
    toggleBlockOpen: (blockOpen: boolean) => void;
}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    return (
        <>
            <DrawerHeader sx={{justifyContent: 'flex-start', marginTop: {xs: 1.5, md: 2.5}}}>
                <Link sx={{width: '100%', px: 1, mr: 'auto'}}>
                    <Logo85Icon style={{height: 'auto', width: 36}} />
                </Link>
                {open && isMd && (
                    <IconButton onClick={() => toggleBlockOpen(!blockOpen)} size={'small'}>
                        {blockOpen ? <AdjustOutlinedIcon /> : <CircleOutlinedIcon />}
                    </IconButton>
                )}
            </DrawerHeader>
            <LeftPanelList {...{open}} />
        </>
    );
};

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));
