// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    height: 100vh;
    width: 100vw;
}

#root {
    height: 100%;
    width: 100vw;
    overflow: auto;
}

body {
    margin: 0;
    overflow-x: hidden;
    padding-right: 0 !important;
}

header {
    padding-right: 0 !important;
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    margin: 0px;
    padding: 0px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-webkit-autofill::first-line {
    font-size: 16px;
    box-shadow: none !important;
    -webkit-text-fill-color: inherit !important;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/app.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;;;;;;;;;;IAUI,eAAe;IAEf,2BAA2B;IAC3B,2CAA2C;IAC3C,yDAAiD;IAAjD,iDAAiD;AACrD","sourcesContent":["html {\n    height: 100vh;\n    width: 100vw;\n}\n\n#root {\n    height: 100%;\n    width: 100vw;\n    overflow: auto;\n}\n\nbody {\n    margin: 0;\n    overflow-x: hidden;\n    padding-right: 0 !important;\n}\n\nheader {\n    padding-right: 0 !important;\n}\n\na {\n    text-decoration: none;\n    color: inherit;\n}\n\np {\n    margin: 0px;\n    padding: 0px;\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ntextarea:-webkit-autofill,\ntextarea:-webkit-autofill:hover,\ntextarea:-webkit-autofill:focus,\nselect:-webkit-autofill,\nselect:-webkit-autofill:hover,\nselect:-webkit-autofill:focus,\ninput:-webkit-autofill::first-line {\n    font-size: 16px;\n    -webkit-box-shadow: none !important;\n    box-shadow: none !important;\n    -webkit-text-fill-color: inherit !important;\n    transition: background-color 5000s ease-in-out 0s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
