export const cleanData = (data: {[s: string]: unknown} | ArrayLike<unknown>) =>
    Object.entries(data).reduce((a, [k, v]) => (v == null || v === '' ? a : ((a[k] = v), a)), {} as {[k: string]: any});

export const stringToColor = (string: string) => {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
};

export const stringAvatar = (name: string) => {
    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`
    };
};

export const capitalizeFLetter = (string: string) => {
    if (!string) return string;
    return string[0].toUpperCase() + string.slice(1);
};

export const fullName = (me: any) => {
    return (
        capitalizeFLetter(me?.first_name) +
        ' ' +
        capitalizeFLetter(me?.last_name) +
        ' ' +
        capitalizeFLetter(me?.patronymic)
    );
};
