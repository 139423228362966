import {Stack, Typography} from '@mui/material';

export const Home = () => {
    return (
        <Stack>
            <Typography>Главная страница</Typography>
            {'Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\n' +
                '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\n' +
                '            наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                '            получения тестового контента.\n' +
                '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\n' +
                '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\n' +
                '            наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                '            получения тестового контента.\n' +
                '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\n' +
                '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\n' +
                '            наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                '            получения тестового контента.Генерация рыбатекста происходит довольно просто: есть несколько фиксированных\n' +
                '            наборов фраз и словочетаний, из которых в опредёленном порядке формируются предложения. Предложения\n' +
                '            складываются в абзацы — и вы наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                '            получения тестового контента.Генерация рыбатекста происходит довольно просто: есть несколько фиксированных\n' +
                '            наборов фраз и словочетаний, из которых в опредёленном порядке формируются предложения. Предложения\n' +
                '            складываются в абзацы — и вы наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                '            получения тестового контента.\n' +
                '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\n' +
                '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\n' +
                '            наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                '            получения тестового контента.\n' +
                '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\n' +
                '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\n' +
                '            наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                '            получения тестового контента.vv\n' +
                '            vvГенерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\n' +
                '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\n' +
                '            наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                '            получения тестового контента.\n' +
                '\n' +
                '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\n' +
                '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\n' +
                '            наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                '            получения тестового контента.\n' +
                '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\n' +
                '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\n' +
                '            наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                '            получения тестового контента.\n' +
                '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\n' +
                '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\n' +
                '            наслаждетесь очередным бредошедевром.\n' +
                '\n' +
                '            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\n' +
                '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\n' +
                '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\n' +
                "            получения тестового контента. 'Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\\n' +\n" +
                "                    '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\\n' +\n" +
                "                    '            наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента.\\n' +\n" +
                "                    '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\\n' +\n" +
                "                    '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\\n' +\n" +
                "                    '            наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента.\\n' +\n" +
                "                    '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\\n' +\n" +
                "                    '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\\n' +\n" +
                "                    '            наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента.Генерация рыбатекста происходит довольно просто: есть несколько фиксированных\\n' +\n" +
                "                    '            наборов фраз и словочетаний, из которых в опредёленном порядке формируются предложения. Предложения\\n' +\n" +
                "                    '            складываются в абзацы — и вы наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента.Генерация рыбатекста происходит довольно просто: есть несколько фиксированных\\n' +\n" +
                "                    '            наборов фраз и словочетаний, из которых в опредёленном порядке формируются предложения. Предложения\\n' +\n" +
                "                    '            складываются в абзацы — и вы наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента.\\n' +\n" +
                "                    '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\\n' +\n" +
                "                    '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\\n' +\n" +
                "                    '            наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента.\\n' +\n" +
                "                    '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\\n' +\n" +
                "                    '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\\n' +\n" +
                "                    '            наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента.vv\\n' +\n" +
                "                    '            vvГенерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\\n' +\n" +
                "                    '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\\n' +\n" +
                "                    '            наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента.\\n' +\n" +
                "                    '\\n' +\n" +
                "                    '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\\n' +\n" +
                "                    '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\\n' +\n" +
                "                    '            наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента.\\n' +\n" +
                "                    '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\\n' +\n" +
                "                    '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\\n' +\n" +
                "                    '            наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента.\\n' +\n" +
                "                    '            Генерация рыбатекста происходит довольно просто: есть несколько фиксированных наборов фраз и словочетаний,\\n' +\n" +
                "                    '            из которых в опредёленном порядке формируются предложения. Предложения складываются в абзацы — и вы\\n' +\n" +
                "                    '            наслаждетесь очередным бредошедевром.\\n' +\n" +
                "                    '\\n' +\n" +
                '                    \'            Сама идея работы генератора заимствована у псевдосоветского "универсального кода речей", из которого мы\\n\' +\n' +
                "                    '            выдернули используемые в нём словосочетания, запилили приличное количество собственных, в несколько раз\\n' +\n" +
                "                    '            усложнили алгоритм, добавив новые схемы сборки, — и оформили в виде быстрого и удобного сервиса для\\n' +\n" +
                "                    '            получения тестового контента."}
        </Stack>
    );
};
