import cookies from 'js-cookie';
import {removeTokenAsArray} from 'utils/functions/index';

export const getTokens = () => ({
    accessToken: cookies.get('accessToken')
    // refreshToken: cookies.get('refreshToken')
});
export const setTokens = (response: any) => {
    cookies.set('accessToken', response?.access_token);
    // cookies.set('refreshToken', response?.refresh_token);
};
export const removeTokens = () => {
    const thisToken = cookies.get('accessToken');
    removeTokenAsArray(thisToken as string);
    cookies.remove('accessToken');
    // cookies.remove('refreshToken');
};
