const onCreateCroppedPreview = ({completedCrop, previewCanvasRef, imgRef, uploadNewAvatar, fileName}: any) => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
        return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
    );
    const compressedData = canvas.toDataURL('image/jpeg', 0.9);
    let compressedBlob: BlobPart | null = null;
    fetch(compressedData)
        .then((res) => res.blob())
        .then((res) => (compressedBlob = res));
    canvas.toBlob(
        (blob: any) => {
            const file = new File([compressedBlob || blob], fileName || 'name', {type: 'image/png'});
            const fileWithPreview = Object.assign(file, {
                preview: URL.createObjectURL(file)
            });
            uploadNewAvatar(fileWithPreview);
        },
        'image/png',
        0.7
    );
};

export default onCreateCroppedPreview;
