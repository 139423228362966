import React, {useCallback, useRef, useState} from 'react';
import {Box, Button, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import {useDropzone} from 'react-dropzone';
import ReactCrop, {Crop} from 'react-image-crop';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import 'react-image-crop/dist/ReactCrop.css';
import onCreateCroppedPreview from 'utils/functions/onCreateCroppedPreview';
import {closeModal} from 'context/modalEffector';

type PhotoModalType = {
    setPhoto: any;
};

export const UploadAvatarModal = ({setPhoto}: PhotoModalType) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [file, setFile] = useState<any>();
    const [fileName, setFileName] = useState<string>('');
    const [crop, setCrop] = useState<Crop>({
        unit: '%',
        x: 25,
        y: 25,
        width: 50,
        height: 50
    });
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const ext = {'.pdf': [], '.png': [], '.jpeg': [], '.jpg': [], '.bmp': [], '.tiff': [], '.heic': [], '.heif': []};
    const uploadNewAvatar = (cropFile: any) => {
        setPhoto(cropFile);
        closeModal();
        clear();
    };
    const onDrop = useCallback((acceptedFiles: any) => {
        setFileName(acceptedFiles?.[0].name);
        const reader = new FileReader();
        reader.addEventListener('load', () => setFile(reader.result));
        reader.readAsDataURL(acceptedFiles[0]);
    }, []);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: ext
    });
    const clear = () => {
        setFile(null);
    };

    return (
        <>
            <Box sx={{p: 2, border: '1px solid #dee2e6'}}>
                {file ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: 340,
                            maxWidth: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <ReactCrop
                            crop={crop}
                            aspect={1}
                            onChange={(c: any) => setCrop(c)}
                            onComplete={(c: any) => setCompletedCrop(c)}>
                            <img src={file} height={'auto'} style={{aspectRatio: 1}} ref={imgRef} />
                        </ReactCrop>
                    </Box>
                ) : (
                    <Button
                        variant={'contained'}
                        color={'inherit'}
                        {...getRootProps()}
                        sx={{
                            mx: 'auto',
                            width: '100%',
                            height: 340,
                            maxWidth: '100%',
                            p: 0,
                            color: 'text.primaryConst',
                            background: '#f4f4f4 !important',
                            boxShadow: 'none !important'
                        }}>
                        <Stack sx={{alignItems: 'center'}} spacing={3}>
                            <AddAPhotoIcon />
                            <Typography sx={{mt: '16px !important'}} color='text.primaryConst'>
                                Добавьте фото
                            </Typography>
                        </Stack>
                    </Button>
                )}
                <input {...getInputProps()} accept='image/*' />
            </Box>
            <canvas ref={previewCanvasRef} style={{display: 'none'}} />;
            <Stack direction={isMd ? 'row' : 'column'} spacing={1} justifyContent={'flex-end'}>
                <Button onClick={clear}>Очистить</Button>
                <Button
                    onClick={() =>
                        onCreateCroppedPreview({
                            completedCrop,
                            previewCanvasRef,
                            imgRef,
                            uploadNewAvatar,
                            fileName
                        })
                    }>
                    Сохранить
                </Button>
            </Stack>
        </>
    );
};
