import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';

import {Stack, Typography} from '@mui/material';
import {FormTextField} from 'components/form/FormTextField';
import {Form} from 'components/form/Form';
import {LoadingButton} from '@mui/lab';
import {Paper} from 'components/accessory/Paper';

import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

import {ERROR_MESSAGE, ROUTES} from 'utils/constants';
import {getGetApiV1AuthenticationUserQueryKey, usePostApiV1AuthenticationLoginOrEmail} from 'api/авторизация';
import {useEffect} from 'react';
import cookies from 'js-cookie';
import {useQsParams} from 'utils/hooks/useQsParams';
import {useQueryClient} from '@tanstack/react-query';

export const Authorization = () => {
    const form = useForm(schema);
    const {reset} = form;
    const navigate = useNavigate();
    const authorizationQuery = usePostApiV1AuthenticationLoginOrEmail();
    const queryClient = useQueryClient();
    const [params] = useQsParams();
    const onSubmit = (data: any) => {
        authorizationQuery
            .mutateAsync({
                data
            })
            .then((r) => {
                console.log(r);
                //TODO: Нет схемы
                //@ts-ignore
                cookies.set('accessToken', r.token);
                //@ts-ignore
                queryClient.setQueryData(getGetApiV1AuthenticationUserQueryKey(), r.data);
                navigate(params.redirectUrl || ROUTES.PROFILE);
                // window.location.href = params.redirectUrl || ROUTES.BASE;
            })
            .catch((error) => {
                console.log('error');
            });
    };
    useEffect(() => {
        reset({loginOrEmail: 'filipp220@yandex.ru', password: '123456'});
    }, []);
    return (
        <Stack
            sx={{
                width: '100%',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: (theme) => theme.palette.background.body
            }}>
            <Form form={form} onSubmit={onSubmit}>
                <Paper sx={{p: 3}}>
                    <Stack gap={3} sx={{width: 400}}>
                        <Typography variant={'h3'}>Авторизация</Typography>
                        <FormTextField name={'loginOrEmail'} placeholder={'Логин'} />
                        <FormTextField name={'password'} password placeholder={'Пароль'} />
                        <LoadingButton
                            variant={'contained'}
                            type={'submit'}
                            size={'large'}
                            loading={authorizationQuery.isPending}>
                            Авторизоваться
                        </LoadingButton>
                    </Stack>
                </Paper>
            </Form>
        </Stack>
    );
};

const schema = {
    resolver: yupResolver(
        yup.object({
            loginOrEmail: yup.string().required(ERROR_MESSAGE.REQUIRED),
            password: yup.string().required(ERROR_MESSAGE.REQUIRED).min(6, 'Минимальное количество символов - 6')
        })
    )
};
