import {CssBaseline, ThemeProvider} from '@mui/material';
import {useRoutes} from 'react-router-dom';
import {routes} from './routes';
import createAppTheme from './theme';
import {useThemeContext} from 'context/themeContext';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {AuthProvider} from 'context/authContext';

const queryClient = new QueryClient();
const App = () => {
    const {theme} = useThemeContext();
    const content = useRoutes(routes);
    return (
        <ThemeProvider theme={createAppTheme(theme)}>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <CssBaseline />
                    {content}
                </AuthProvider>
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export default App;
