import {Paper} from 'components/accessory/Paper';
import {paperPaddingMixin} from 'utils/mixins';
import MDEditor from '@uiw/react-md-editor';
import {useState} from 'react';
import {Stack} from '@mui/material';

export const News = () => {
    const [value, setValue] = useState<string | undefined>(`
# Заголовок 1
## Заголовок 2

- Список 1
- Список 2

**Жирный текст**
*Курсивный текст*

\`\`\`javascript
console.log('Пример кода');
\`\`\`

[Ссылка на сайт](https://www.example.com)

![Изображение](https://sun6-23.userapi.com/c845018/v845018705/72b97/fax8Jc2D3po.jpg)
  `);
    return (
        <Paper
            sx={{
                ...paperPaddingMixin,
                '& .wmde-markdown, .w-md-editor-preview': {
                    color: (theme) => theme.palette.text.primary,
                    background: (theme) => theme.palette.background.paper
                },
                '& .wmde-markdown pre': {
                    color: (theme) => theme.palette.primary.contrastText
                }
            }}>
            <MDEditor value={value} onChange={setValue} height={400} />
            <Stack
                mt={4}
                sx={{
                    '& .wmde-markdown': {
                        color: (theme) => theme.palette.text.primary,
                        background: (theme) => theme.palette.background.paper
                    }
                }}>
                <MDEditor.Markdown source={value} />
            </Stack>
        </Paper>
    );
};
