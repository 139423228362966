import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {useGetApiV1AuthenticationUser} from 'api/авторизация';
import {useLocation} from 'react-router-dom';
import {ROUTES} from 'utils/constants';
import {CircularProgress, Stack} from '@mui/material';
import {usePostApiSsoAuthCheckSession} from 'api/sso-авторизация';
import {getTokensArray} from 'utils/functions';

export const AuthContext = createContext({} as {me: any; isLoading: boolean; usersLogin: null | any[]});

type AuthProviderProps = {
    children: ReactNode;
};
export const AuthProvider = ({children}: AuthProviderProps) => {
    const {pathname} = useLocation();
    const [usersLogin, setUsersLogin] = useState<null | any[]>(null);
    const checkSessionMutate = usePostApiSsoAuthCheckSession();
    const {data, isLoading, isError} = useGetApiV1AuthenticationUser({
        query: {retry: false}
    });
    const isAuth = pathname?.includes(ROUTES.AUTH);
    useEffect(() => {
        if (isError && !isAuth) {
            window.location.href =
                ROUTES.AUTH + `${decodeURIComponent(`?redirectUrl=`)}${encodeURIComponent(window.location.href)}`;
        }
    }, [isError]);

    //список авторизованных пользователей
    useEffect(() => {
        if (!isAuth && !usersLogin) {
            const tokensArray = getTokensArray();
            checkSessionMutate
                .mutateAsync({
                    data: {auth: tokensArray}
                })
                .then((r) => {
                    //@ts-ignore
                    setUsersLogin(r.result);
                })
                .catch((e) => console.log(e));
        }
    }, [pathname]);

    const isEmptyLayout = !isAuth && !data;
    return (
        <AuthContext.Provider value={{me: data, isLoading, usersLogin}}>
            {isEmptyLayout ? <PageLoader /> : children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);

const PageLoader = () => {
    return (
        <Stack sx={{height: '100vh', width: '100vw', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress size={64} />
        </Stack>
    );
};
