import {createEvent, createStore, restore, sample} from 'effector';
import React, {ReactNode} from 'react';
import {CustomModal} from 'components/accessory/CustomModal';
import {useUnit} from 'effector-react';

// Определение событий для открытия и закрытия модального окна
export const openModal = createEvent<handleOpenType>();
export const closeModal = createEvent();

// Создание хранилища для состояния открытия/закрытия модального окна
export const $isModalOpen = createStore(false)
    .on(openModal, () => true)
    .on(closeModal, () => false);

// Создание хранилища для содержимого модального окна
export const $contentModal = restore(openModal, {
    title: '',
    body: ''
});

// Создание семпла для обновления содерцжимого модального окна при открытии
sample({
    source: openModal,
    fn: (payload: handleOpenType) => payload,
    target: $contentModal
});

type handleOpenType = {title?: string | ReactNode; body?: string | ReactNode} & any;

export const ModalEffector = () => {
    const {title, body, ...rest} = useUnit($contentModal);
    return (
        <CustomModal open={useUnit($isModalOpen)} modalTitle={title} onClose={closeModal} {...rest}>
            {body}
        </CustomModal>
    );
};
export const ModalWidth = (width: number) => ({'& .MuiPaper-root': {maxWidth: '100%', width: `${width}px`}});
