import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {AppThemeProvider} from 'context/themeContext';
import 'assets/styles/app.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <AppThemeProvider>
            <App />
        </AppThemeProvider>
    </BrowserRouter>
);

