import {ThemeOptions} from '@mui/material';

export const blackTheme: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#7367f0'
        },
        action: {
            selected: '#EEEEEF'
        }
    }
};
