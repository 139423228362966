import React, {ReactNode} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    SxProps,
    Theme
} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';

export const CustomModal = (
    props: Omit<DialogProps, 'onClose'> & {
        hiddenCloseIcon?: boolean;
        customActions?: ReactNode;
        onClose?: () => void;
        sx?: SxProps<Theme>;
        modalTitle?: string | ReactNode;
        sxDialogTitle?: SxProps<Theme>;
        sxDialogContent?: SxProps<Theme>;
    }
) => {
    const {
        open,
        onClose,
        children,
        customActions,
        hiddenCloseIcon,
        sx,
        modalTitle,
        sxDialogTitle,
        sxDialogContent,
        ...rest
    } = props;
    return (
        <Dialog
            open={open}
            fullWidth
            onClose={onClose}
            maxWidth={'md'}
            sx={{
                p: 1,
                '& .MuiPaper-root': {backgroundImage: 'none'},
                '& .MuiDialog-paper': {margin: {xs: 0, md: 2}},
                ...sx
            }}
            transitionDuration={{exit: 0, enter: 400, appear: 400}}
            {...rest}>
            <DialogTitle id='alert-dialog-title' typography={'h5'} sx={{py: 3, ...sxDialogTitle}}>
                {!hiddenCloseIcon && (
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 12,
                            top: 12,
                            zIndex: 1000,
                            color: (theme) => theme.palette.grey[500]
                        }}>
                        <CloseIcon />
                    </IconButton>
                )}
                {modalTitle}
            </DialogTitle>

            <DialogContent sx={{...sxDialogContent}}>{children}</DialogContent>
            {customActions && <DialogActions sx={{p: 0}}>{customActions}</DialogActions>}
        </Dialog>
    );
};
