import React, {createContext, ReactNode, useContext, useEffect} from 'react';
import {useGetApiV1AuthenticationUser} from 'api/авторизация';
import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTES} from 'utils/constants';
import {CircularProgress, Stack} from '@mui/material';

export const AuthContext = createContext({} as {me: any; isLoading: boolean});

type AuthProviderProps = {
    children: ReactNode;
};
export const AuthProvider = ({children}: AuthProviderProps) => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {data, isLoading} = useGetApiV1AuthenticationUser({
        query: {retry: false}
    });
    const isAuth = pathname?.includes(ROUTES.AUTH);
    useEffect(() => {
        if (isAuth && data) {
            navigate(ROUTES.PROFILE);
        }
    }, [pathname, data]);
    const isEmptyLayout = !isAuth && !data;
    return (
        <AuthContext.Provider value={{me: data, isLoading}}>
            {isEmptyLayout ? <PageLoader /> : children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);

const PageLoader = () => {
    return (
        <Stack sx={{height: '100vh', width: '100vw', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress size={64} />
        </Stack>
    );
};
