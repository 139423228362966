/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {MutationFunction, UseMutationOptions, UseMutationResult} from '@tanstack/react-query';
import {useMutation} from '@tanstack/react-query';
import type {
    PostApiSsoAuthCheckSession200,
    PostApiSsoAuthCheckSession401,
    PostApiSsoAuthCheckSessionBody,
    PostApiSsoAuthLoginAuthRequest200,
    PostApiSsoAuthLoginAuthRequest401,
    PostApiSsoAuthLoginAuthRequestBody,
    PostApiSsoAuthLoginAuthRequestParams,
    PostApiSsoAuthTokenAuthRequest200,
    PostApiSsoAuthTokenAuthRequest401,
    PostApiSsoAuthTokenAuthRequestBody,
    PostApiSsoAuthTokenAuthRequestParams
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Авторизация через форму
 */
export const postApiSsoAuthLoginAuthRequest = (
    postApiSsoAuthLoginAuthRequestBody: PostApiSsoAuthLoginAuthRequestBody,
    params: PostApiSsoAuthLoginAuthRequestParams
) => {
    return axiosInstance<PostApiSsoAuthLoginAuthRequest200>({
        url: `/api/sso/auth/loginAuthRequest`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiSsoAuthLoginAuthRequestBody,
        params
    });
};

export const getPostApiSsoAuthLoginAuthRequestMutationOptions = <
    TError = PostApiSsoAuthLoginAuthRequest401 | string,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>,
        TError,
        {data: PostApiSsoAuthLoginAuthRequestBody; params: PostApiSsoAuthLoginAuthRequestParams},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>,
    TError,
    {data: PostApiSsoAuthLoginAuthRequestBody; params: PostApiSsoAuthLoginAuthRequestParams},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>,
        {data: PostApiSsoAuthLoginAuthRequestBody; params: PostApiSsoAuthLoginAuthRequestParams}
    > = (props) => {
        const {data, params} = props ?? {};

        return postApiSsoAuthLoginAuthRequest(data, params);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiSsoAuthLoginAuthRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>
>;
export type PostApiSsoAuthLoginAuthRequestMutationBody = PostApiSsoAuthLoginAuthRequestBody;
export type PostApiSsoAuthLoginAuthRequestMutationError = PostApiSsoAuthLoginAuthRequest401 | string;

/**
 * @summary Авторизация через форму
 */
export const usePostApiSsoAuthLoginAuthRequest = <
    TError = PostApiSsoAuthLoginAuthRequest401 | string,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>,
        TError,
        {data: PostApiSsoAuthLoginAuthRequestBody; params: PostApiSsoAuthLoginAuthRequestParams},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>,
    TError,
    {data: PostApiSsoAuthLoginAuthRequestBody; params: PostApiSsoAuthLoginAuthRequestParams},
    TContext
> => {
    const mutationOptions = getPostApiSsoAuthLoginAuthRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Проверка текущей сессии
 */
export const postApiSsoAuthCheckSession = (postApiSsoAuthCheckSessionBody: PostApiSsoAuthCheckSessionBody) => {
    return axiosInstance<PostApiSsoAuthCheckSession200>({
        url: `/api/sso/auth/checkSession`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiSsoAuthCheckSessionBody
    });
};

export const getPostApiSsoAuthCheckSessionMutationOptions = <
    TError = PostApiSsoAuthCheckSession401 | string,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>,
        TError,
        {data: PostApiSsoAuthCheckSessionBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>,
    TError,
    {data: PostApiSsoAuthCheckSessionBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>,
        {data: PostApiSsoAuthCheckSessionBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiSsoAuthCheckSession(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiSsoAuthCheckSessionMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>
>;
export type PostApiSsoAuthCheckSessionMutationBody = PostApiSsoAuthCheckSessionBody;
export type PostApiSsoAuthCheckSessionMutationError = PostApiSsoAuthCheckSession401 | string;

/**
 * @summary Проверка текущей сессии
 */
export const usePostApiSsoAuthCheckSession = <
    TError = PostApiSsoAuthCheckSession401 | string,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>,
        TError,
        {data: PostApiSsoAuthCheckSessionBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>,
    TError,
    {data: PostApiSsoAuthCheckSessionBody},
    TContext
> => {
    const mutationOptions = getPostApiSsoAuthCheckSessionMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Авторизация по токену пользователя
 */
export const postApiSsoAuthTokenAuthRequest = (
    postApiSsoAuthTokenAuthRequestBody: PostApiSsoAuthTokenAuthRequestBody,
    params: PostApiSsoAuthTokenAuthRequestParams
) => {
    return axiosInstance<PostApiSsoAuthTokenAuthRequest200>({
        url: `/api/sso/auth/tokenAuthRequest`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiSsoAuthTokenAuthRequestBody,
        params
    });
};

export const getPostApiSsoAuthTokenAuthRequestMutationOptions = <
    TError = PostApiSsoAuthTokenAuthRequest401 | string,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>,
        TError,
        {data: PostApiSsoAuthTokenAuthRequestBody; params: PostApiSsoAuthTokenAuthRequestParams},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>,
    TError,
    {data: PostApiSsoAuthTokenAuthRequestBody; params: PostApiSsoAuthTokenAuthRequestParams},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>,
        {data: PostApiSsoAuthTokenAuthRequestBody; params: PostApiSsoAuthTokenAuthRequestParams}
    > = (props) => {
        const {data, params} = props ?? {};

        return postApiSsoAuthTokenAuthRequest(data, params);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiSsoAuthTokenAuthRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>
>;
export type PostApiSsoAuthTokenAuthRequestMutationBody = PostApiSsoAuthTokenAuthRequestBody;
export type PostApiSsoAuthTokenAuthRequestMutationError = PostApiSsoAuthTokenAuthRequest401 | string;

/**
 * @summary Авторизация по токену пользователя
 */
export const usePostApiSsoAuthTokenAuthRequest = <
    TError = PostApiSsoAuthTokenAuthRequest401 | string,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>,
        TError,
        {data: PostApiSsoAuthTokenAuthRequestBody; params: PostApiSsoAuthTokenAuthRequestParams},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>,
    TError,
    {data: PostApiSsoAuthTokenAuthRequestBody; params: PostApiSsoAuthTokenAuthRequestParams},
    TContext
> => {
    const mutationOptions = getPostApiSsoAuthTokenAuthRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
